<template>
  <div id="codefilling-form">
    <h2 class="mb-3 text-center codefilling-form-title mt-0 text-white">{{tr('main-form-title')}}</h2>
    <CodeFillingText />
    <div class=" codefilling-inner-row">
      <!-- FORM -->
      <validation-observer
          v-if="formStatus === false && !maximumUploads && !winnerMessage"
          v-slot="{ handleSubmit }"
          tag="div"
          ref="mainForm"
      >
        <form
            id="promotionCodeForm"
            class="form form--codefilling row"
            @submit.prevent="handleSubmit(formSubmit)">
          <div class="col-md-12 col-lg-10 offset-lg-1">
            <div class="codefilling-form codefilling-form--inner-wrap">              
              <!-- vezetéknév, keresztnév -->
              <div class="form-row w-100">
                <!-- keresztnév -->
                <div class="forename-row input--info" :class="$store.state.currentLang == 'hu' ? 'order-1':''" :style="$store.state.currentLang == 'hu' ? 'margin-right: 0px;':'margin-right: 12px;'">
                  <ValidationProvider :rules="{ min: 2, required: true}" v-slot="{ failedRules, errors }" tag="div" vid="forename">
                    <div class="d-flex align-itemx-center">
                        <div class="form-label">{{tr('main-form-keresztnev')}}<span class="ml-1 error">*</span></div>
                        <span
                            class="icon-info"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="tr('main-form-keresztnev-tooltip')"
                        ></span>                    
                    </div>
                    <input
                        v-model="form.forename"
                        type="text"
                        :placeholder="tr('main-form-keresztnev-placeholder')"
                        class="form-control"
                        name="forename"                        
                        id="forname"
                        :tabindex="$store.state.currentLang == 'hu' ? 2:1"
                    />
                    <span class="error" v-if="getErrorMessage('forname', Object.entries(failedRules)[0])">{{
                        getErrorMessage("forname", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>
                <div class="surname-row input--info" :class="$store.state.currentLang == 'hu' ? 'order-0':''" :style="$store.state.currentLang == 'hu' ? 'margin-right: 12px;':'margin-right: 0px;'">
                  <!-- vezetéknév -->
                  <ValidationProvider :rules="{ min: 2, required: true}" v-slot="{ failedRules, errors }" tag="div" vid="surname">
                    <div class="d-flex align-itemx-center">
                      <div class="form-label">{{tr('main-form-vezeteknev')}}<span class="ml-1 error">*</span></div>
                      <div
                          class="icon-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tr('main-form-vezeteknev-tooltip')"
                      ></div>                  
                    </div>
                    <input
                        v-model="form.surname"
                        type="text"
                        class="form-control"
                        name="surname"
                        :placeholder="tr('main-form-vezeteknev-placeholder')"
                        id="surname"
                        :tabindex="$store.state.currentLang == 'hu' ? 1:2"
                    />
                    <span class="error" v-if="getErrorMessage('surname', Object.entries(failedRules)[0])">{{
                        getErrorMessage("surname", Object.entries(failedRules)[0])
                      }}</span>
                      <span class="error" v-else>{{errors[0]}}</span>   
                  </ValidationProvider>
                </div>
              </div>
              <!-- email cím -->
              <div class="form-row w-100">
                <div class="email-row input--info">
                  <ValidationProvider
                      rules="email|required"
                      v-slot="{ failedRules, errors }"
                      vid="email"
                      tag="div"
                  >
                    <div class="d-flex align-itemx-center">
                      <div class="form-label">{{tr('main-form-email')}}<span class="ml-1 error">*</span></div>
                      <span
                          class="icon-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tr('main-form-email-tooltip')"
                      ></span>                      
                    </div>
                    <input
                        v-model="form.email"
                        type="text"
                        :placeholder="tr('main-form-email-placeholder')"
                        class="form-control"
                        name="email"
                        id="email"
                        ref="emailAddress"
                        tabindex="3"
                    />
                    <span class="error" v-if="getErrorMessage('email', Object.entries(failedRules)[0])">{{
                        getErrorMessage("email", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>
                <div class="email-confirm-row input--info">
                  <ValidationProvider
                      rules="required|confirmed:email"
                      v-slot="{ failedRules, errors }"
                      tag="div"
                      vid="emailConfirm"
                  >                    
                    <div class="form-label">{{tr('main-form-email-again')}}<span class="ml-1 error">*</span></div>
                    <input
                        v-model="form.emailConfirm"
                        type="text"
                        :placeholder="tr('main-form-email-placeholder')"
                        class="form-control"
                        name="email-confirm"
                        id="email-confirm"
                        tabindex="4"
                    />
                    <span class="error" v-if=" getErrorMessage('emailConfirm',Object.entries(failedRules)[0])">{{
                        getErrorMessage(
                            "emailConfirm",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-row w-100">          
                <div class="input--info phone-row">                  
                    <div class="form-label">{{tr('main-form-phone')}}</div>                  
                    <input
                        v-model="form.phone"
                        type="text"
                        :placeholder="tr('main-form-phone-placeholder')"
                        class="form-control"
                        name="phone"
                        id="phone"
                        ref="phone"
                        tabindex="5"
                    />      
                </div>               
              </div>
              <!-- vásárlás típusa, boltok -->
              <div class="form-row w-100">                
                <div>
                    <label for="">{{tr('main-form-buy-type')}}<span class="ml-1 error">*</span></label>
                    <b-form-group class="form__custom-checkbox-wrap">
                      <b-form-radio v-model="form.buy_type" name="buy-type" value="shop" :class="{'selected': form.buy_type == 'shop'}">{{tr('main-form-buy-type-shop')}}</b-form-radio>
                      <b-form-radio v-model="form.buy_type" name="buy-type" value="online" :class="{'selected': form.buy_type == 'online'}">{{tr('main-form-buy-type-online')}}</b-form-radio>
                    </b-form-group>
                </div>
                <div>  
                   <ValidationProvider
                      rules="required"
                      v-slot="{ failedRules, errors }"
                      tag="div"
                      vid="where_bought"
                  >                     
                      <label for="">{{tr('main-form-where-buy')}}<span class="ml-1 error">*</span></label>     
                      <!-- üzletek -->
                      <b-form-select v-if="form.buy_type == 'shop'" v-model="shop_where_bought" name='where_bought'>
                        <b-form-select-option :value="null">{{tr('main-form-choose-shop')}}</b-form-select-option>
                        <b-form-select-option :value="shop" v-for="(shop, sindex) in shops" :key="sindex">{{shop}}</b-form-select-option>
                      </b-form-select>
                      <!-- online bolt lista -->
                      <b-form-select v-if="form.buy_type == 'online'" v-model="online_where_bought" name='where_bought'>
                        <b-form-select-option :value="null">{{tr('main-form-choose-shop')}}</b-form-select-option>
                        <b-form-select-option :value="shop" v-for="(shop, sindex) in onlineShops" :key="sindex">{{shop}}</b-form-select-option>
                      </b-form-select>
                   <span class="error" v-if="getErrorMessage('where_bought',Object.entries(failedRules)[0])">{{
                        getErrorMessage(
                            "where_bought",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>                                                
              </div>
              <!-- only SHOP - AP kód, vásárlás ideje -->
              <div class="form-row w-100">
                <!-- AP - HU -->
                <div class="apCode-wrap apCode-wrap--hu apCode-row input--info" v-if="$store.state.currentLang == 'hu' && form.buy_type == 'shop'">
                  <ValidationProvider
                      :rules="{ min: 8, required: true, regex: /^\d{8}$/ }"
                      v-slot="{ failedRules, errors }"
                      vid="code"
                  >
                    <div class="d-flex align-itemx-center">                      
                        <div class="form-label">{{tr('codefillin-ap-code')}}<span class="ml-1 error">*</span></div>
                        <span
                          class="icon-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tr('main-form-AP-hu')"
                      ></span>
                    </div>
                    <div class="position-relative">
                      <span class="apText">AP A</span>                                        
                      <input
                          v-mask="'########'"                        
                          v-model="form.code"
                          type="text"
                          class="form-control"
                          name="code"
                          id="ap-code-hu"     
                          tabindex="6"                   
                      />
                    </div>
                    <span class="error" v-if="getErrorMessage('code', Object.entries(failedRules)[0])">{{
                        getErrorMessage("code", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>   
                <!-- AP - egyéb nyelv -->
                <div class="apCode-wrap apCode-row input--info" v-if="form.buy_type == 'shop' && $store.state.currentLang != 'hu'">
                  <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ failedRules, errors }"
                      vid="code"
                  >
                    <div class="d-flex align-itemx-center">                      
                        <div class="form-label">{{tr('codefillin-ap-code')}}<span class="ml-1 error">*</span></div>
                        <span
                          class="icon-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tr('main-form-AP')"
                      ></span>
                    </div>                    
                    <input                                                                        
                        v-model="form.code"
                        type="text"
                        class="form-control"
                        name="code"
                        id="ap-code"
                        placeholder="01234"
                        tabindex="7"
                    />
                    <span class="error" v-if="getErrorMessage('code', Object.entries(failedRules)[0])">{{
                        getErrorMessage("code", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                  <!-- magyarázó blokk - online esetében is van egy ilyen link -->
                  <div v-if="$store.state.currentLang == 'cz'"><a :href="`${baseUrl}documents/${$store.state.currentLang}_receipt_example.pdf`" target="_blank" class="ap-code-pdf-link">{{tr('main-form-ap-code-block-pdf')}}</a></div>
                </div>  
                <!-- online vásárlás - számlaazonosító -->
                <div class="code-wrap code-row input--info" v-if="form.buy_type == 'online'">
                   <ValidationProvider                      
                      :rules="{ required: true }"
                      v-slot="{ failedRules, errors }"
                      vid="code"
                  >
                    <div class="d-flex align-itemx-center">                      
                        <div class="form-label">{{tr('codefilling-szamlaazonosito')}}<span class="ml-1 error">*</span></div>
                        <!-- <span
                          class="icon-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tr('main-form-online-code-hu')"
                      ></span> -->
                    </div>                    
                    <input                                                
                        v-model="form.code"
                        type="text"
                        class="form-control"
                        name="code"
                        id="online-code"
                        placeholder="ABC123"
                        tabindex="8"
                    />
                    <span class="error" v-if="getErrorMessage('code', Object.entries(failedRules)[0])">{{
                        getErrorMessage("code", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                  <!-- magyarázó blokk - shop esetében is van egy ilyen link -->
                 <!-- <div v-if="$store.state.currentLang == 'sk' || $store.state.currentLang == 'cz'">
                    <a :href="`${baseUrl}/documents/${$store.state.currentLang}_receipt_example.pdf`" target="_blank" class="ap-code-pdf-link">{{tr('main-form-ap-code-block-pdf')}}</a>
                  </div>-->
                </div> 
                <!-- vásárlás időpontja -->
                <div 
                      class="form-group-top form-group--info purchase_date-row input--info"
                  >                                     
                    <b-input-group class="date-picker-group mb-3 flex-row">
                      <!-- DATE -->
                      <div :class="{'flex-fill': form.buy_type == 'online'}">
                        <div class="form-label">{{tr('main-form-purchase-date')}}<span class="ml-1 error">*</span></div>       
                        <ValidationProvider
                            class="w-100"
                            rules="required"
                            v-slot="{ failedRules, errors }"
                            vid="purchase_date"
                        >
                          <DatePicker
                              :value="null"
                              v-model="dateValue"                               
                              mode="date"
                              :model-config="modelConfig"
                              :locale="locale"
                              :masks="dateMasks[locale]"
                              id="vc-date-picker" 
                          >
                            <template v-slot="{ inputValue, inputEvents  }">
                                <input
                                  type="text"                                  
                                  :value="inputValue"                                    
                                  v-on="inputEvents"                                     
                                  :placeholder="tr('main-form-date-picker-placeholder')"
                                  tabindex="9"
                                />
                              </template>
                          </DatePicker>                          
                          <span class="error ml-1 mr-1" v-if="getErrorMessage('purchase_date',Object.entries(failedRules)[0])">{{
                              getErrorMessage(
                                  "purchase_date",
                                  Object.entries(failedRules)[0]
                              )
                            }}</span>
                          <span class="error" v-else>{{errors[0]}}</span>                            
                        </ValidationProvider>
                      </div>
                      <!-- TIME -->
                      <div v-if="form.buy_type == 'shop'" class="time-picker-container">
                        <div class="form-label">{{tr('main-form-puchase-time')}}<span class="ml-1 error">*</span></div>  
                        <ValidationProvider
                            class="w-100 time-picker__inner"
                            rules="required"
                            v-slot="{ failedRules, errors }"
                            vid="time"
                        >
                          
                            <DatePicker
                              :value="null"
                              v-model="timeValue"  
                              :model-config="modelConfigforTime"                           
                              mode="time"                              
                              :locale="locale"
                              is24hr
                              id="vc-time-picker"
                              
                          >
                            <template v-slot="{ inputValue, inputEvents  }">
                                <input
                                  type="text"                                  
                                  :value="inputValue"                                    
                                  v-on="inputEvents"    
                                  tabindex="10"
                                  :placeholder="tr('main-form-time-picker-placeholder')"
                                />
                              </template>
                          </DatePicker>                           
                          
                          <span class="error ml-1 mr-1" v-if="getErrorMessage('time',Object.entries(failedRules)[0])">{{
                              getErrorMessage(
                                  "time",
                                  Object.entries(failedRules)[0]
                              )
                            }}</span>
                          <span class="error" v-else>{{errors[0]}}</span>  
                        </ValidationProvider>
                      </div>
                    </b-input-group>
                </div>           
              </div>       

              <!-- BLOKK FELTÖLTÉS -->
              <div class="form-row mb-0">
                <div class="col-12">                       
                        <div class="img-uploads receipt-row row">     
                          <div class="col-12 ">
                            <div class="d-flex align-center">
                              <div class="form-label">{{tr('main-form-img-upload')}}<span class="ml-1 error">*</span></div>
                              <div
                                class="icon-info"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="tr('main-form-blokk-upload-tooltip')"
                            ></div>
                            </div> 
                            <label class="img-upload " for="receipt">
                              <input
                                  type="file"
                                  id="receipt"
                                  ref="receipt"
                                  name="receipt"
                                  @change="handleFilesUpload()"
                                  tabindex="11"
                              />
                              <div class="img-upload__text d-flex align-items-center">
                                <img src="@/assets/img/icons/ic-blokk.svg" />
                                <div>
                                  <p style="font-weight: 800;">{{tr('codefilling-blokk-title')}}</p>
                                  <p>{{tr('codefilling-blokk-sub-title')}}</p>
                                </div>
                              </div>
                            </label>
                          </div>                     
                          <div class="col-12 ">                                                  
                            <!-- feltöltött fájlok -->
                            <div class="uploaded-files ">                              
                              <label class="form-label mt-3 mt-lg-0 pt-4" v-if="uploadedFiles.length > 0">{{tr('codefilling-uploaded-file')}}:</label>                              

                              <div v-if="form.receipt1 != null">
                                <span class="upload-text">{{ form.receipt1.name }}</span>
                                <div
                                    class="delete"
                                    @click="delUploadedFile(form.receipt1)"
                                ></div>
                              </div>
                              <div v-if="form.receipt2 != null">
                                <span class="upload-text">{{ form.receipt2.name }}</span>
                                <div
                                    class="delete"
                                    @click="delUploadedFile(form.receipt2)"
                                ></div>
                              </div>
                              <div v-if="form.receipt3 != null">
                                <span class="upload-text">{{ form.receipt3.name }}</span>
                                <div
                                    class="delete"
                                    @click="delUploadedFile(form.receipt3)"
                                ></div>
                              </div>                           
                            </div>
                            <!-- fájl feltöltés error -->
                            <div
                                class="uploaded-error error"
                                ref="uploadederror"
                            ></div>
                            <span v-if="imageError" class="error">{{tr('main-form-block-error')}}</span>
                            <span v-if="imageApiError" class="error">{{tr('main-form-block-API-error')}}</span>
                          </div>
                        </div>
                </div>
              </div>
            </div>
          </div>
          <!-- checkbox -->
          <div class="col-md-12 col-lg-10 offset-lg-1 ">
            <div class="upload-bg">
              <div class="col-12 winner-codefilling__text ">
                <!-- játékszabály elfogadása -->
                <div class="custom-control custom-checkbox checkbox-margin condition-row">
                  <ValidationProvider
                      rules="required|acceptConditions"
                      v-slot="{ failedRules, errors }"
                      tag="div"
                      vid="condition"
                  >
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.condition"
                        type="checkbox"
                        class="custom-control-input"
                        id="conditions"
                        name="condition"
                    />
                    <label
                        class="custom-control-label mb-0 condition-label"
                        for="conditions"
                    >
                      <div>
                        {{tr('main-form-chbx-1')}}
                        <span style="text-decoration:none; color: red;"><strong>*</strong></span>
                      </div>

                    </label>
                    <div class="mt-4"></div>
                    <span class="error" v-if="getErrorMessage('condition', Object.entries(failedRules)[0])">{{
                        getErrorMessage("condition", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>
             
                  <div class="custom-control custom-checkbox" v-if="$store.state.currentLang != 'ro'">
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.consent"
                        type="checkbox"
                        class="custom-control-input"
                        id="consent"
                        name="consent"
                    />
                    <label class="custom-control-label mb-0 condition-label" for="consent">
                      <div>
                        {{tr('main-form-chbx-2')}}                        
                      </div>
                    </label>
                    <div class="mt-4"></div>
                  </div>
          

                <!--<div class="custom-control custom-checkbox">
                  <input
                      true-value="1"
                      false-value="0"
                      v-model="form.newsletter"
                      type="checkbox"
                      class="custom-control-input"
                      id="newsletter"
                      name="newsletter"
                  />
                  <label class="custom-control-label" for="newsletter">
                    <div>
                      {{tr('main-form-chbx-3')}}
                    </div>
                  </label>

                </div>      -->          

                <div class="text-center mt-3 send-form-btn">
                  <button type="submit" class="btn btn-secondary">
                    {{tr('main-form-submit-btn')}}
                  </button>
                </div>

                <div class="alert alert-danger" v-if="formSubmitError">
                  {{tr('codefilling-error-unknown')}}
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
      

      <!-- ----------FORM RESULT ------------- -->        
      <div id="formResultBlock"
          ref="formResultBlock"
          v-if="formStatus === true"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-8 offset-lg-2"
      >
        <!-- maximum feltöltés - össz max. feltöltés -->
         <div v-if="maximumUploads"
            class="codefilling-form codefilling-form--inner-wrap border-radius-message-card form-error-message ">
          <div
              class="col-12 m-auto text-center order-nyertes"              
          >
            <!-- Error message - maximum feltöltés -->
            <div class="main-h1-notwin mt-0">{{tr('main-form-result-unsuccess-title')}}</div>
            <p>
              {{tr('main-form-result-unsuccess-text-max-upload-full')}}</p>
            <!--<div class="winning-img--error">
              <img class="img-fluid" src="@/assets/img/form/ic-unsuccess.png" alt=""/>
            </div>-->
            
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-secondary"
                      @click="backToForm();setGTM('maximum_feltoltes_landing','maximum_feltoltes_landing','gomb_kattintas','fooldal')">
                {{tr('main-form-new-code-upload-btn-text')}}
              </button>
            </div>
          </div>
        </div>
        <!-- egy nap maximum feltöltés -->
        <div v-if="maximumDailyUploads"
            class="codefilling-form codefilling-form--inner-wrap border-radius-message-card form-error-message ">
          <div
              class="col-12 m-auto text-center order-nyertes"              
          >
            <!-- Error message - maximum feltöltés egy nap -->
            <div class="main-h1-notwin mt-0">{{tr('main-form-result-unsuccess-title')}}</div>
            <p>
              {{tr('main-form-result-unsuccess-text-max-upload')}}</p>
            <!--<div class="winning-img--error">
              <img class="img-fluid" src="@/assets/img/form/ic-unsuccess.png" alt=""/>
            </div>-->
            
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-secondary"
                      @click="backToForm();">
                {{tr('main-form-new-code-upload-btn-text')}}
              </button>
            </div>
          </div>
        </div>
        <div v-if="!maximumUploads && !maximumDailyUploads"
             class="codefilling-form  codefilling-form--inner-wrap border-radius-message-card form-error-message form-error-message--unsuccess ">
             <!-- SIKERTELEN BEKÜLDÉS - érvénytelen AP kód -->
          <div
              class="col-12 m-auto text-center order-nyertes p-0"
              v-if="(uploadedError === true || alreadyUploadedCode)"
          >            
            <div class="main-h1-notwin mt-0">{{tr('main-form-result-unsuccess-title')}}</div>
            <!--<div class="winning-img--error">
              <img src="@/assets/img/form/ic-unsuccess.png" class="img-fluid" alt=""/>
            </div> -->
            <p>{{tr('main-form-result-unsuccess-text-already')}}</p>                      
            <!--<p class="mb-4 font-weight-bold text-blue mt-4">{{tr('main-form-result-upload-new-code')}}</p>-->
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-secondary"
                      @click="backToForm();setGTM('sikertelen_landing','sikertelen_landing','gomb_kattintas','megprobalom_ujra')">
                {{tr('main-form-new-code-upload-btn-text')}}
              </button>
            </div>
          </div>
          <!-- SIKERES BEKÜLDÉS -->
          <div
              v-if="!uploadedError && !alreadyUploadedCode"
              class="col-12 m-auto text-center order-nyertes p-0 form-error-message--unsuccess"
          >
            <div class="main-h1-notwin mt-0">{{tr('main-form-result-success-title')}}</div>
            <div class="text mb-3">
              <!--<div class="nem-nyert-img-wrap">
                <img src="@/assets/img/form/ic-success.png" class="img-fluid nem-nyert-img"/>
              </div>-->
              <div class="form-result__sub-title">
                {{tr('main-form-result-success-sub-title')}}
              </div>
              <p class="text-dark" v-html="tr('main-form-result-success-lead')"></p>
              <p class="font-weight-bold mt-4 text-primary">{{tr('main-form-result-success-text')}}</p>
            </div>
            <div class="row ml-auto justify-content-center mr-auto mt-4">
              <div class="btn-wrap justify-content-center m-md-1">
                <button class="mt-0  btn btn-secondary"
                        @click="backToForm();setGTM('sikeres_landing','sikeres_landing','gomb_kattintas','ujabb_kodot_toltok_fel')">
                  {{tr('main-form-new-code-upload-btn-text')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import $ from "jquery";
/* eslint-disable */
import {ValidationObserver} from "vee-validate";
import { ValidationProvider, extend,
} from "vee-validate/dist/vee-validate.full.esm";
import {required, email, regex, confirmed} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import CodeFillingText from "@/components/CodeFillingText.vue"
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

setInteractionMode("passive");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"]  
});

extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  }  
});

extend("email", {
  ...email  
});
extend("required", {
  ...required  
});
extend("confirmed", {
  ...confirmed  
});

export default {
  data() {
    const date = new Date();
    const purchaseDate = `${this.dateValue} + ${this.timeValue}`
    return {      
      dateValue:null,
      timeValue:null,
      modelConfig:{       
        type: 'string',                
        mask: 'YYYY-MM-DD'
      },   
      modelConfigforTime:{       
        type: 'string',                
        mask: 'HH:mm'
      },
      dateMasks:{
        hu: {
          input: 'YYYY-MM-DD'
        },
        ro: {
          input: 'DD-MM-YYYY'
        },
        sk: {
          input: 'DD-MM-YYYY'
        },
        cz: {
          input: 'DD-MM-YYYY'
        }
      },                     
      show: false,      
      form: {
        code: "",
        phone: "",
        surname: "",
        forename: "",
        buy_type:'shop',
        purchase_date: "",
        email: "",
        emailConfirm: "",
        condition: "",
        newsletter: "",
        consent: "",        
        where_bought:null,
        receipt1: null,
        receipt2: null,
        receipt3: null,
        receipt4: null,
        receipt5: null,
      },
      shop_where_bought:null,
      online_where_bought:null,
      maxFileUploadNum:3,
      // shops: this.$store.state.shops.shops,
      // onlineShops: this.$store.state.shops.online,      
      winnerMessage: false,
      responseErrors: null,
      uploadedError: false, 
      alreadyUploadedCode: false,      
      winType: undefined,
      winner: undefined,
      formIsSubmitting: false,
      maximumUploads: false,
      maximumDailyUploads:false,
      imageError: false,
      imageApiError:null,
      uploadedFiles: [],
      formStatus: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      formSubmitError:false
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    CodeFillingText,
    Calendar,
    DatePicker 
  },  
  computed:{
    locale(){
      return this.$store.state.currentLang
    },
    shops(){
      return this.$store.state.shops.shops
    },
    onlineShops(){
      return this.$store.state.shops.online
    } 
  },
  watch: {
    formStatus(newData) {
      window.scroll(
          0,
          this.findPos(document.getElementById("codefilling-form")) - 76
      );

      this.$parent.backgroundChanger = newData      
    },
    dateValue(){
      this.timeValue = "";
    }    
  },
  methods: {
    setGTM(item, category, action, button) {
      let btnSucc = this.overEighteen ? 'sikeres' : 'sikertelen'

      this.GTtrackGA4({
        'event': 'event',
        'category': category,
        'action': button,
        'label': item,
        'button': 'kodfeltoltes',
        'clicked_text': item,
        'success': btnSucc
      })
    },    
    handleFilesUpload() {
      let _this = this;
      //remove the error msg if there was
      this.$refs.uploadederror.innerHTML = "";

      let uploadedItem = this.$refs.receipt.files[0];

      //check file size 5Mb max
      let maxFileSize = 1024 * 1024 * 5;
      let fileSize = uploadedItem.size;

      //check file fomat
      // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

      //check file size - max 2Mb
      if (fileSize > maxFileSize) {
        this.$refs.uploadederror.innerHTML = _this.tr('file-upload-big-size')
            // "Túl nagy a fájl mérete (max. 5Mb)";
        // } else if (isGoodExtension == false) {
        //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
      } else {
        if (_this.uploadedFiles.length < parseInt(_this.maxFileUploadNum)) {
          //max 5 item could be uploaded

          let nameIndex = _this.uploadedFiles.find(
              (item) => item.name == uploadedItem.name
          );

          if (nameIndex == undefined) {
            _this.uploadedFiles.push(uploadedItem);
          } else {
            this.$refs.uploadederror.innerHTML = _this.tr('fileUpload-error-same-name')
          }
        } else {
          _this.$refs.uploadederror.innerHTML = _this.tr('fileUpload-error-not-more-file')
        }
      }

      this.fillReceipts();

      //set the default state for the input type=file
      this.$refs.receipt.value = ""

      if (this.form.receipt1 != null) {
        this.imageError = false;
      }
    },
    fillReceipts() {
      let _this = this;

      _this.form.receipt1 = null;
      _this.form.receipt2 = null;
      _this.form.receipt3 = null;
      _this.form.receipt4 = null;
      _this.form.receipt5 = null;

      this.uploadedFiles.forEach(function (item, i) {
        if (i == 0) {
          _this.form.receipt1 = item;
        } else if (i == 1) {
          _this.form.receipt2 = item;
        } else if (i == 2) {
          _this.form.receipt3 = item;
        } 
        else if (i == 3) {
          _this.form.receipt4 = item;
        } else if (i == 4) {
          _this.form.receipt5 = item;
        }
      });
    },
    delUploadedFile(delItem) {
      let _this = this
      let delItemIndex = this.uploadedFiles.indexOf(delItem);

      if (delItemIndex != -1) {
        _this.uploadedFiles.splice(delItemIndex, 1);
      }

      this.$refs.uploadederror.innerHTML = "";
      this.fillReceipts();
    },
    removeError(fieldName) {
      $(`#promotionCodeForm .${fieldName}-row .error-2`).remove();
    },      
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [curtop];
      }
    },
    getFormData(){
          let _this = this
          let formData = new FormData();

          formData.append("surname", _this.form.surname);
          formData.append("forename", _this.form.forename);          
          formData.append("buy_type", _this.form.buy_type);
          if (_this.form.buy_type == 'shop'){
            formData.append("where_bought", _this.shop_where_bought);
          } else {
            formData.append("where_bought", _this.online_where_bought);
          }
          

          if (_this.form.buy_type == 'shop' && _this.$store.state.currentLang == 'hu'){
            formData.append("code", 'APA' + _this.form.code);
          } else {
            formData.append("code", _this.form.code);
          }
          
          formData.append("phone", _this.form.phone);
          formData.append("email", _this.form.email);
          formData.append("condition", _this.form.condition);                       

          if (_this.form.buy_type == 'shop'){
            formData.append("purchase_date", _this.dateValue+" "+_this.timeValue);
          } else {
            //let dateTimeArr = _this.dateTimeValue.split(' ')            
            formData.append("purchase_date", _this.dateValue);
          }
          
          formData.append("newsletter", _this.form.newsletter);
          formData.append("consent", _this.form.consent);
          formData.append("receipt1", _this.form.receipt1);
          formData.append("receipt2", _this.form.receipt2);
          formData.append("receipt3", _this.form.receipt3);
          formData.append("receipt4", _this.form.receipt4);
          formData.append("receipt5", _this.form.receipt5);

          return formData
    },
    goToResultBlock(){
      let _this = this
       setTimeout(() => {                          
          let element = _this.$refs.formResultBlock
          if (element){
              _this.scrollToElement(element, 20)       
          }           
      }, 1000)
    },
    formSubmit() {
        let _this = this    

        this.imageApiError = false

        //kötelező blokkot feltölteni
        if (!_this.form.receipt1) {
          _this.imageError = true;
          return;
        }

        //check that the form was submitted
        if (!_this.formIsSubmitting) {
          _this.formIsSubmitting = true

          let formData = _this.getFormData()

          _this.$axios.post(process.env.VUE_APP_API_URL + 'step1/upload', formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {                

                if (response.data.status) {
                  //SUCCESS                  
                  var _this = this;                  
                  //show the win or not win block instead of the codefilling form
                  _this.formStatus = true;  
                  _this.goToResultBlock()              
                  _this.backToForm(true);

                } else {
                  _this.formSubmitError = true
                }

                _this.formIsSubmitting = false

              }).catch(function (error) {
                //ERROR               

                //document.getElementById('promotionCodeForm').scrollIntoView(true);

                _this.formIsSubmitting = false
                console.log(error);

                if (!error.response.data.status) {

                  let errorsArray = Object.entries(error.response.data.error)
                  for (const [errorName, errorValue] of errorsArray) {
                      if (errorName == "code") {
                        //change the form block text
                        _this.formStatus = true;                        
                        _this.uploadedError = true;
                        _this.alreadyUploadedCode = true; 
                        _this.goToResultBlock()              
                      } else if (errorName === "maximum_uploads") {
                        _this.formStatus = true;  
                        _this.maximumUploads = true;         
                        _this.goToResultBlock()                         
                      } else if (errorName === "maximum_daily_uploads"){
                        _this.formStatus = true;  
                        _this.maximumDailyUploads = true;    
                        _this.goToResultBlock()                              
                      } else if (errorName.includes('receipt')){
                        console.log('receipt problem')
                        _this.imageApiError = true
                      }else {
                        console.log("egyéb error", errorName, errorValue)
                        _this.goToError(error)                        
                      }
                  }

                }
              });
        } else {
          console.log('double form submit')
        }

    },
     goToError(err){            
        let _this = this
        //scroll to the first error
        this.setErrorMsgs(err, 'mainForm').then(()=>{                               
          let firstError = document.querySelector('#promotionCodeForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }
          //document.getElementById('promotionCodeForm').scrollIntoView(true);
        })                                       
    },
    backToForm(skipFormStatusReset) {
      this.form.surname = "";
      this.form.forename = "";
      this.form.code = "";
      this.form.birthDate = "";
      this.form.phone = "";
      this.form.purchase_date = "";
      this.form.email = "";
      this.form.emailConfirm = "";
      this.form.condition = "";
      this.form.newsletter = "";
      this.form.consent = "";
      this.form.where_bought = null;
      this.uploadedError = false;
      this.alreadyUploadedCode = false;
      this.winner = false;
      this.dateValue = '';
      this.timeValue = '';
      this.winType = undefined;
      this.hash = null;
      this.overEighteen = true;
      this.maximumUploads = false;
      this.maximumDailyUploads = false;
      this.winnerMessage = false;
      this.form.receipt1 = null;
      this.form.receipt2 = null;
      this.form.receipt3 = null;
      this.form.receipt4 = null;
      this.form.receipt5 = null;
      this.formIsSubmitting = false;
      this.uploadedFiles = [];
      this.imageApiError = null
      
      if (!skipFormStatusReset) {
        this.formStatus = false;
      }
    }

  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();   
    
    console.log('modelConfig[locale]', this.modelConfig[this.locale])
  }
  ,
  updated() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ,
}
;
</script>

<style lang="scss"></style>
